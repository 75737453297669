<template>
    <el-dialog
            :title="'回复消息'"
            :close-on-click-modal="false"
            :visible.sync="visible"
            center>
        <p>{{messageInfo.fromPerson}}:{{messageInfo.content}}</p>

        <el-form :model="replyForm" :rules="rules" ref="replyForm">
            <el-form-item label="" prop="content">
                <el-input v-model="replyForm.content"  type="textarea" placeholder="回复内容"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="reply">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "message-reply",
        data(){
            return{
                visible: false,
                replyForm:{
                    content: '',
                },
                rules:{
                    content: [
                        { required: true, message: '回复内容不能为空', trigger: 'blur' }
                    ],
                }
            }
        },
        props: {
            messageInfo: {
                type: Object,
                defaults: {}
            }
        },
        methods:{
            init(){
                this.visible = true
                this.replyForm.content=''
            },
            reply(){
                this.$refs['replyForm'].validate((valid) => {
                    if (valid) {

                        this.visible = false

                        this.$message.success("回复成功")
                    }
                })



            }
        }
    }
</script>

<style scoped>

</style>
